//
//
//
//

export default {
    name: 'App',
    created() {
        this.$router.onReady(() => {
            localStorage.setItem('api-auth', this.$route.query.api)
        })
    },
}
